import {React, useState, useEffect} from "react";
import Meeting from './Meeting';
import MeetingDetails from './MeetingDetails';
import Breadcrumbs from '../Breadcrumbs';

const Meetings = ({api}) => {

    const [meetings, setMeetings] = useState([]);
    const [meeting, setMeeting] = useState();

    useEffect(() => {

        api.getMeetings()
        .then(result => {
            setMeetings(result);
    
        })
        .catch(err => {

        });


      }, [api, meeting]);



      function breadcrumbClick(target)
      {
        if(target === "Meetings")
        {
          setMeeting();
        }
      }



    function RenderComponent()
    {
        if(meeting)
        {
          return  <MeetingDetails api={api} meeting={meeting} onReturn={breadcrumbClick} />
        }
        else
        {
          return <>
            <Breadcrumbs crumbs={breadcrumbArray} onCrumbClick={breadcrumbClick} />
     
            {meetings.map(meeting => (<Meeting meeting={meeting} key={meeting.id} onClick={setMeeting} />))} 
           
            </>
        }
    }



    var breadcrumbArray = ['Meetings'];

    return (
        <>
          
            
          <div className="books">
            {RenderComponent()} 
            
          </div>
        </>
      );
    
    
    }
    export default Meetings;