import {React, useState, useEffect} from "react";
import BookAutoComplete from '../Controls/BookAutoComplete'
import AuthorAutoComplete from '../Controls/AuthorAutoComplete'
import TextField from '../Controls/TextField'
import DateField from '../Controls/DateField'

const AddBook = ({api}) => {

    const [book, setBook] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [imageURL, setImageURL] = useState('https://');
    const [displayURL, setDisplayURL] = useState('https://');
    const [publishedDate, setPublishedDate] = useState();
    const [notes, setNotes] = useState('');
    const errorURL = "https://static.vecteezy.com/system/resources/previews/005/720/408/original/crossed-image-icon-picture-not-available-delete-picture-symbol-free-vector.jpg";



    useEffect(() => {

      console.log(book);
      if(book && book.id > 0) {

          api.getBook(book.id)
          .then(result => {
            
            setImageURL(result.imageURL);
            setPublishedDate(result.Published);
            setFirstName(result.author);
            setLastName(result.author);
            console.log(result.imageURL);
      
          })
          .catch(err => {

          });


      }


    }, [api, book]);


    useEffect(() => {


      checkIfImageExists(imageURL, setDisplayURL);


    }, [imageURL]);

    function checkIfImageExists(url, callback) {

      
      const img = new Image();
      
      img.src = url;
  
      if (img.complete) {
        callback(url);
      } 
      else {
          img.onload = () => {
            callback(url);
          };
          
          
          img.onerror = () => {
            callback(errorURL);
            
          
          }
      }

    }

    function onSubmit() {

        let obj = {

            book_id: book.id,
            Title: book.Title,
            author_id: (firstName.id === lastName.id) ? firstName.id : 0,
            FirstName: firstName.FirstName,
            LastName: lastName.LastName,
            imageURL: imageURL,
            Published: publishedDate,
            notes: notes

        };

        api.saveRecommendation(obj)
        .then(result => {
          
          console.log(result);
    
        })
        .catch(err => {
          console.log(err);
        });

        console.log(obj);

    }


    return (
        <div className="addBook" >
          <div className="formField">
          <BookAutoComplete  api={api} onSelection={setBook} />            
          </div>
          <div className="formField">
          <AuthorAutoComplete  api={api} value={firstName} setValue={setFirstName} default={firstName} attr="FirstName" />
          </div>
          <div className="formField">
          <AuthorAutoComplete  api={api} value={lastName} setValue={setLastName} default={lastName} attr="LastName" />
          </div>
          <div className="formField">
          <TextField label="Image URL" value={imageURL} setValue={setImageURL} />
          </div>
          <div className="formField">
            <img src={displayURL || null} alt="cover" />
          </div>
          <div className="formField">
          <DateField label="Published Date" value={publishedDate} setValue={setPublishedDate}   />
          </div>

          <div className="formField">
          <p>Recommendation notes:</p>
          <textarea value={notes} onChange={(e) => setNotes(e.target.value)} ></textarea>
          </div>



          <div className="formField">
          <button onClick={onSubmit}>Submit</button>
          </div>

        </div>
      );
    
    
    }
    export default AddBook;