import {React} from "react";
import Rating from '@mui/material/Rating';

const Book = ({book, onClick}) => {

    function recommendations() {
      if(book.recommendation > 1)
      {
          return <p>This book has been recommendation {book.recommendation} times.</p>;
      }
      else if(book.recommendation > 0)
      {
          return <p>This book has been recommended.</p>;
      }
    }

    return (
        <div className="book flex" onClick={(e) => onClick(book)}>
            <div className="image">
              <img src={book.imageURL} alt={book.Title} />
            </div>
            <div className="details">
              <h1>{book.Title}</h1>
              <p>Author: {book.author.FirstName} {book.author.LastName}</p>
              <p>Published:  {book.Published}</p>
              <div className="rating"><label>Average:</label> <Rating id="avgRating" name="avg-rating" value={book.rating} precision={0.5} readOnly /></div>
              <div className="rating"><label>My Rating:</label> <Rating name="my-rating" value={book.myreview ? book.myreview.rating : 0} precision={0.5} readOnly /></div>
              {recommendations()}
            </div>

        </div>
      );
    
    
    }
    export default Book;