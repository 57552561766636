import {React, useState, useEffect} from "react";
import Book from './Book';
import BookDetails from './BookDetails';
import Breadcrumbs from '../Breadcrumbs';

const Books = ({api}) => {

    const [books, setBooks] = useState([]);
    const [book, setBook] = useState();

    useEffect(() => {

        api.getBooks()
        .then(result => {
            setBooks(result);
    
        })
        .catch(err => {

        });


      }, [api, book]);



      function breadcrumbClick(target)
      {
        if(target === "Books")
        {
          setBook();
        }
      }



    function RenderComponent()
    {
        if(book)
        {
          return  <BookDetails api={api} book={book} onReturn={breadcrumbClick} />
        }
        else
        {
          return <>
            <Breadcrumbs crumbs={breadcrumbArray} onCrumbClick={breadcrumbClick} />

              


              {books.map(book => (<Book book={book} key={book.id} onClick={setBook}/>))}
           
            </>
        }
    }



    var breadcrumbArray = ['Books'];

    return (
        <>
          
            
          <div className="books">
            {RenderComponent()} 
            
          </div>
        </>
      );
    
    
    }
    export default Books;