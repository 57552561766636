import {React} from "react";

const Breadcrumbs = ({crumbs, onCrumbClick}) => {

    function handleClick(e)
    {
        onCrumbClick(e.target.innerHTML);
    }

    return (
        <div className="breadcrumbs">
           <button></button>
           {crumbs.map(crumb => (<button key={crumb} onClick={handleClick}>{crumb}</button>))}

        </div>
      );
    
    
    }
    export default Breadcrumbs;