import React from "react";

const Menu = ({onSelection}) => {

    function onMenuClick(e) {

      onSelection(e.target.innerHTML);
    }

    return (
        <div className="menu">

            <button onClick={onMenuClick} className="menuItem">
              Home
            </button>

            <button onClick={onMenuClick} className="menuItem">
              Books
            </button>

            <button onClick={onMenuClick} className="menuItem">
              Meetings
            </button>

            <button onClick={onMenuClick} className="menuItem">
              Recommend
            </button>

        </div>
      );
    
    
    }
    export default Menu;