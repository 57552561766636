
import React, {useState, useEffect} from "react";
import API from './API/API';
import Login from './components/Login';
import Register from './components/Register';
import Main from './components/Main';
import Header from './components/Header/Header';
import './style/style.css'
import { Routes, Route } from 'react-router-dom';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

const api = new API();


function App() {

  const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {

      setLoggedIn(api.isLoggedIn());

    }, []);

    function renderComponent() {

      if(!loggedIn)
      {
        return <Login api={api} onLogin={setLoggedIn} />
      }
      else
      {
        return <Main api={api} />
      }

    }

  return (
    <div className="App flex">
      <Header api={api} setLoggedIn={setLoggedIn} />

  
      <Routes>
          <Route path="/" element={renderComponent()} />
          <Route path="/forgot" element={<ForgotPassword api={api} />} />
          <Route path="/reset" element={<ResetPassword api={api} />} />
          <Route path="/register" element={<Register api={api} />} />
       </Routes>
    </div>
  );
}

export default App;
