import {React} from "react";
import Rating from '@mui/material/Rating';

const Review = ({review}) => {

    return (
        <div className="review" >
          
          <div className="author">
            <p>{review.reviewer.name}</p>
          </div>
          <div className="rating"><Rating id="avgRating" name="avg-rating" value={review.rating} precision={0.5} readOnly /></div>
          <div className="reviewBody">
            <p>{review.review}</p>
          </div>


        </div>
      );
    
    
    }
    export default Review;