import {React, useState} from "react";

const ResetPassword = ({api}) => {

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, onError] = useState('');
  const [message, setMessage] = useState('');

  const buttonClicked = () => {

    api.resetPassword(code, password, passwordConfirmation)
      //.then(result => result.data)
      .then((result) => {
        setMessage(result.message);
        onError('');
      })
      .catch(err => {
          onError(err);
          setMessage('');
      });

  }

  return (

      <div className="login">

        <div className="inputDiv">
          <label htmlFor="code">Code</label>
          <input id="code" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
      
        </div>

        <div className="inputDiv">
          <label htmlFor="password">Password</label>
          <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div className="inputDiv">
          <label htmlFor="passwordConfirmation">Confirm Password</label>
          <input id="passwordConfirmation" type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
        </div>

        <div className="inputDiv">
          <button onClick={buttonClicked}>Submit</button>
        </div>   <div className="inputDiv">
          {message}
          {error}
        </div>




        <div className="inputDiv">
          <a href="/">Return to login screen</a>
      </div>

</div>
  );


}
export default ResetPassword;