import {React, useState, useEffect} from "react";

import MeetingDetails from "./Meeting/MeetingDetails";
import Breadcrumbs from './Breadcrumbs';

const Welcome = ({api}) => {

    const[nextMeeting, setNextMeeting] = useState();

    useEffect(() => {

        api.getNextMeeting()
        .then(result => {
            setNextMeeting(result);
        })
        .catch(err => {

        });


      }, [api]);

      var breadcrumbArray = ['Home'];

      function RenderNextMeeting() {
        if(nextMeeting)
        {
          return <>
            <MeetingDetails api={api} meeting={nextMeeting} hideBreadcrumbs="true" />
          </>
        }
        else return <></>;
      }

      function breadcrumbClick() {

      }

    return (
        <>
        <Breadcrumbs crumbs={breadcrumbArray} onCrumbClick={breadcrumbClick} />
        {RenderNextMeeting()}
        </>
      );
    
    
    }
    export default Welcome;