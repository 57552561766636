import React from "react";



function Header({api, setLoggedIn}) {

    function renderLoginDetails()
{
    if(api.isLoggedIn())
    {
        return  <>
                    <div className="welcome">Welcome back {api.getUsername()}</div>
                    <button className="logout" onClick={(e) => { api.logout();setLoggedIn(false) }}>logout</button>
                </>
    }
}

  return (

      <header className="App-header">
            <img className="logo" src="logo.png" alt="bookends logo"></img>

            {renderLoginDetails()}
    
      </header>

  );
}

export default Header;
