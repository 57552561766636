import {React, useState, useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import Alert  from '@mui/material/Alert';

const Question = ({api, question, book_id}) => {

    const[answerObj, setAnswerObj] = useState();
    const[answer, setAnswer] = useState('');

    useEffect(() => {

      if(question.MyAnswer)
      {
        
        setAnswerObj(question.MyAnswer);
      }

    }, [question])

    useEffect(() => {

      if(answerObj)
      {
        
        setAnswer(answerObj.answer ? answerObj.answer : {});
      }

    }, [answerObj])


    function SaveAnswer(e)
    {
        if(api && answer.length > 0)
        {
          e.target.innerHTML = 'Saving...';

          api.saveAnswer((answerObj && answerObj.id) ? answerObj.id : null,
            question.id,
            book_id,
            answer
            )
            .then(result => {
                setAnswerObj(result);
                setOpen(true);
                e.target.innerHTML = 'Save';
      
          })
          .catch(err => {
  
          });
        }
    }

    const [open, setOpen] = useState(false);



    const handleClose = (e, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    return (
        <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Answer saved!
          </Alert>
        </Snackbar>

        <Accordion className="question" >
          
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography>{question ? question.Question : ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <textarea value={answer} onChange={(e) => { setAnswer(e.target.value) }}></textarea>
              <button onClick={SaveAnswer}>Save</button>

              {question.answers.map(answer => (
                <div key={answer.id} className="review">
                  <div><strong>{answer.User.name} said</strong></div>
                  <div>{answer.answer}</div>
                </div>
              ))} 
            </Typography>
          </AccordionDetails>

        </Accordion>
        </>
      );
    
    
    }
    export default Question;