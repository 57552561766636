import {React, useState} from "react";

const Register = ({api, onLogin, onError, onReturn}) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const registerClicked = () => {

    api.register(name, email, password, confirmPassword)
      .then(result => {
        onError('');
        onLogin(true);
      })
      .catch(err => {
        onError(err);
      });

  }

  return (

      <div className="login">
        <div className="inputDiv">
          <label htmlFor="name">Name</label>
          <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="inputDiv">
          <label htmlFor="email">Email</label>
          <input id="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="inputDiv">
          <label htmlFor="password">Password</label>
          <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div className="inputDiv">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input id="confirmPassword" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>

        <div className="inputDiv">
          <button onClick={registerClicked}>Login</button>
        </div>

        <div className="inputDiv">
          <a href="/">Return to login page</a>
        </div>

      </div>
  );


}
export default Register;