import {React, useEffect, useState} from "react";
import Rating from '@mui/material/Rating';
import Breadcrumbs from '../Breadcrumbs';
import Review from './Review';
import SubmitReview from './SubmitReview';

const BookDetails = ({api, book, onReturn}) => {

  var breadcrumbArray = ['Books', book.Title];

    const [reviews, setReviews] = useState([]);
    const [myReview, setMyReview] = useState();

    useEffect(() => {

      api.getReviews(book.id)
      .then(result => {
         setReviews(result);
      })
      .catch(err => {

      });


    }, [api, book, myReview]);

    function promptReview() {

      if(!book.myreview)
      {
        return <SubmitReview api={api} book={book} onSubmit={setMyReview} />
      }

    }

    return (
        <>
        <Breadcrumbs crumbs={breadcrumbArray} onCrumbClick={onReturn} ></Breadcrumbs>
        <div className="book detail flex">
            <div className="image">
              <img src={book.imageURL} alt={book.Title} />
            </div>
            <div className="details">
              <h1>{book.Title}</h1>
              <p>Author: {book.author.FirstName} {book.author.LastName}</p>
              <p>Published:  {book.Published}</p>
              <div className="rating"><label>Average:</label> <Rating id="avgRating" name="avg-rating" value={book.rating} precision={0.5} readOnly /></div>
              <div className="rating"><label>My Rating:</label> <Rating name="my-rating" value={book.myreview ? book.myreview.rating : 0} precision={0.5} readOnly /></div>
              
              {promptReview()}

              {reviews.map(review => (<Review review={review} key={review.id} />))}
            </div>

        </div>
        </>
      );
    
    
    }
    export default BookDetails;