import {React, useState} from "react";
import Menu from './Menu';
import Books from './Book/Books';
import AddBook from './Book/AddBook';
import Meetings from './Meeting/Meetings';
import Welcome from './Welcome';

const Main = ({api}) => {

    const [menuOption, setMenuOption] = useState('');


    function renderComponent() {

        switch(menuOption)
        {
            case "Books":
                return <Books api={api} />
            case "Meetings":
                return <Meetings api={api} />
            case "Recommend":
                return <AddBook api={api} />
            default:
                return <Welcome api={api} />
        }

    }



    return (
        <div className="main flex">
            <div className="column left">
                    <Menu onSelection={setMenuOption} />
            </div>
            <div className="column right">
                    {renderComponent()}
            </div>
        </div>
      );
    
    
    }
    export default Main;