import {React} from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';


const DateField = ({label, value, setValue}) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack spacing={3} sx={{ width: 500 }}>
      <MobileDatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            value={value}
            onChange={setValue}
            renderInput={(params) => <TextField {...params} />}
          />
    </Stack>
    </LocalizationProvider>
  );
}
export default DateField;