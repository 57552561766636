import {React} from "react";
import Questions from '../Question/Questions';
import Breadcrumbs from '../Breadcrumbs';

const MeetingDetails = ({api, meeting, onReturn, hideBreadcrumbs}) => {

  var breadcrumbArray = ['Meetings', meeting.book.Title];

    function createMarkup() {

      if(meeting && meeting.book)
      {
        return {__html: meeting.book.blurb};
      }

    }

    function RenderBreadcrumbs()
    {
      if(!hideBreadcrumbs)
      {
          return <Breadcrumbs crumbs={breadcrumbArray} onCrumbClick={onReturn} ></Breadcrumbs>;
      }
    }

  return (

    <>
       {RenderBreadcrumbs()}
      <div className="home flex">
             
              <div className="meetingDetails">
                <p>Next meeting on: {(meeting) ? meeting.date : "TBA"}</p>
                
                <p>Next Book: {(meeting && meeting.book) ? meeting.book.Title : ""}</p>
              </div>

              <div className="cover">
                <img alt="Current Book" src={(meeting && meeting.book) ? meeting.book.imageURL : ""} />
              </div>

              <div className="blurb" dangerouslySetInnerHTML={createMarkup()}></div>
              
              
              
              <Questions api={api} book_id={(meeting && meeting.book) ? meeting.book.id : 0} />
      </div>
      </>
    );
    
    
    }
    export default MeetingDetails;