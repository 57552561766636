import {React, useState} from "react";

const ForgotPassword = ({api}) => {

  const [email, setEmail] = useState('');
  const [error, onError] = useState('');

  const buttonClicked = () => {

    api.forgot(email)
      .then(result => {
          window.location.href = "/reset";
      })
      .catch(err => {
        onError(err);
      });

  }

  return (
    <>
      <div className="login">

      <div className="inputDiv">{error}</div>

      <div className="inputDiv">
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>

      <div className="inputDiv">
        <button onClick={buttonClicked}>Submit</button>
      </div>

      </div>


    </>
  );


}
export default ForgotPassword;