import * as React from 'react';
import Box from '@mui/material/Box';
import MaterialTextField from '@mui/material/TextField';

const TextField = ({label, value, setValue}) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '500px' },
      }}
      noValidate
      autoComplete="off"
    >
      <MaterialTextField id="outlined-basic" label={label} defaultValue={value} value={value} onChange={(e) => setValue(e.target.value)} variant="outlined" />
    </Box>
  );
}
export default TextField;