import {React, useState} from "react";

const Login = ({api, onLogin}) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, onError] = useState('');

  const loginClicked = () => {

    api.login(username, password)
      .then(result => {
        onError('');
        onLogin(true);
      })
      .catch(err => {
        onError(err);
      });

  }

  return (
    <div className="login">

      <div className="inputDiv">
        {error}
      </div>
      
      <div className="inputDiv">
        <label htmlFor="Username">username</label>
        <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>

      <div className="inputDiv">
        <label htmlFor="password">Password</label>
        <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>

      <div className="inputDiv">
        <button onClick={loginClicked}>Login</button>
      </div>

      <div className="inputDiv">
        <a href="register">New? Register here</a>
        <br />
        <a href="forgot">Forgot your password?</a>
      </div>

    </div>
  );


}
export default Login;