import {React} from "react";

const Meeting = ({meeting, onClick}) => {

    return (
      <div className="book flex" onClick={(e) => onClick(meeting)}>
            <div className="image">
              <img alt={meeting.book.Title} src={meeting.book.imageURL} />
            </div>
            <div className="details">
              <h1>{meeting.book.Title}</h1>
              <p>Date Scheduled: {meeting.date}</p>
              <p>Author: {meeting.book.author.FirstName} {meeting.book.author.LastName}</p>
              <p>Published:  {meeting.book.Published}</p>
            </div>

        </div>
      );
    
    
    }
    export default Meeting;