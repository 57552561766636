

class API {

    #url = process.env.REACT_APP_API;
    //#url = "http://127.0.0.1:8000/api/";
    #store;
    #loggedIn = false;
    #token = null;
    #username = null;

    constructor() {

        this.#store = ((typeof window) !== 'undefined') ?
            window.sessionStorage :
            ({ getItem: () => "Item", setItem: (k,v) => null });

        this.#token = this.#store.getItem('token');
        if(this.#token)
        {
            this.#loggedIn = true;
        }

    }

    getToken()
    {
        return this.#token;
    }

    isLoggedIn() {
        return this.#loggedIn;
    }

    logout()
    {
        this.#loggedIn = false;
        this.#store.removeItem('token');
    }

    getUsername() {
        return this.#username;
    }

    login(username, password) {

        const requestData = {};
        requestData.email = username;
        requestData.password = password;

        return this.#transact("signin", "POST", requestData)
            .then(r => r.data)
            .then(r => 
                {
                    this.#token = r.token;
                    this.#username = r.name;
                    this.#store.setItem('token', r.token);
                    this.#loggedIn = true;
                    return Promise.resolve()
                })
            .catch(err => {
                    this.#token = null;
                    this.#store.setItem('token', null);
                    this.#loggedIn = false;
                    return Promise.reject(err)
            });
    }

    register(name, email, password, confirmPassword) {

        const requestData = {};
        requestData.name = name;
        requestData.email = email;
        requestData.password = password;
        requestData.confirm_password = confirmPassword;

        return this.#transact("register", "POST", requestData)
            .then(r => r.data)
            .then(r => 
                {
                    this.#token = r.token;
                    this.#store.setItem('token', r.token);
                    this.#loggedIn = true;
                    return Promise.resolve()
                })
            .catch(err => {
                    this.#token = null;
                    this.#store.setItem('token', null);
                    this.#loggedIn = false;
                    return Promise.reject(err)
            });
    }

    forgot(email) {

        const requestData = {};
        requestData.email = email;
        return this.#transact("password/email", "POST", requestData)
            .then(r => r.data)
            .then(r => 
                {

                    return Promise.resolve()
                })
            .catch(err => {

                    return Promise.reject(err)
            });
    }

    resetPassword(code, password, password_confirmation) {

        const requestData = {};
        requestData.code = code;
        requestData.password = password;
        requestData.password_confirmation = password_confirmation;
        return this.#transact("password/reset", "POST", requestData)
            .then(r => r.data)
            .then(r => 
                {

                    return Promise.resolve(r)
                })
            .catch(err => {

                    return Promise.reject(err)
            });
    }

    saveReview(book_id, rating, review) {

        const requestData = {};
        requestData.book_id = book_id;
        requestData.rating = rating;
        requestData.review = review;

        return this.#transact("reviews", "POST", requestData)
            .then(r => r.data)
            .then(r => 
                {
                    return Promise.resolve(r)
                })
            .catch(err => {
                    return Promise.reject(err)
            });
    }

    getBooks() {

        const requestData = {};

        return this.#transact("books", "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getBook(book_id) {

        const requestData = {};

        let url = ['books', book_id].join('/');

        return this.#transact(url, "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getTitles() {

        const requestData = {};

        return this.#transact("titles", "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getAuthors() {

        const requestData = {};

        return this.#transact("authors", "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getReviews(book_id) {

        const requestData = {};

        let url = ['books', book_id, 'review'].join('/');

        return this.#transact(url, "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getQuestions(book_id) {

        const requestData = {};

        let url = ['books', book_id, 'answers'].join('/');

        return this.#transact(url, "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    saveRecommendation(recObj) {
        return this.#transact('recommendations', 'POST', recObj)
            .then(r => 
                {
                    return Promise.resolve(r)
                })
            .catch(err => {
                    return Promise.reject(err)
            });
    }

    saveAnswer(id, question_id, book_id, answer) {

        const requestData = {};
        requestData.book_id = book_id;
        requestData.standardquestion_id = question_id;
        requestData.answer = answer;

        let url = "answers";

        let method = 'POST';
        if(id)
        {
            method = 'PUT';
            url = [url, id].join('/');
        }

        return this.#transact(url, method, requestData)
            .then(r => r.data)
            .then(r => 
                {
                    return Promise.resolve(r)
                })
            .catch(err => {
                    return Promise.reject(err)
            });
    }


    getNextMeeting() {

        const requestData = {};

        return this.#transact("meeting", "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getMeetings() {

        const requestData = {};

        return this.#transact("meetings", "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    getAnswers(question_id, book_id) {

        const requestData = {};

        let url = ['books', book_id, 'questions', question_id, 'answers'].join('/');

        return this.#transact(url, "GET", requestData)
        .then(r => r.data)
        .then(r => 
            {
                return Promise.resolve(r)
            })
        .catch(err => {

                return Promise.reject(err)
        });
    }

    #transact(name, method, data) {

        const headers = {
            'Content-Type': 'application/json'
        };

        const options = {
            method: method,
            headers: headers,
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'error',
            referrerPolicy: 'origin-when-cross-origin',
        };

        if(method === "POST" ||
            method === "PUT")
        {
            options.body = JSON.stringify(data);
        }

        if(this.#token)
        {
            headers.Authorization = 'Bearer ' + this.#token;
        }

        const result = new Promise(
            (resolve, reject) => {


                fetch(this.#url + name, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                      
                            if(result.success)
                            {
                                resolve(result);
                            }
                            else
                            {
                                reject(result.message);
                            }
                            
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            reject(result.message);
                        }
                    );


            }
        )

        return result;

    }


}

export default API;