import {React, useState, useEffect} from "react";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const AuthorAutoComplete = ({api, value, setValue, attr}) => {

      const NewAuthor = {
        id:0,
        Name: ""
      };

      const [authorList, setauthorList] = useState([]);
      
      useEffect(() => {

        api.getAuthors()
        .then(result => {

                  //setFirstNameObject(value);
            const uniqueNames = [];
            //const uniqueObjs = [];

            const uniqueObjs = result.filter(element => {
              const isDuplicate = uniqueNames.includes(element[attr]);

                if (!isDuplicate) {
                  uniqueNames.push(element[attr]);
                 

                  return true;
                }

                return false;
              });



            setauthorList(uniqueObjs);
        })
        .catch(err => {
  
        });
  
  
      }, [api, attr]);






      return (
        <Stack spacing={2} sx={{ width: 500 }}>
          <Autocomplete sx={{ width: 500 }}
            id="author-picker-firstname"
            freeSolo
            options={authorList}
            getOptionLabel={option => option[attr]}
            
            renderInput={(params) => <TextField {...params} label={attr} />}

            value={value || null}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}

            inputValue={value ? value[attr] : ''}
            onInputChange={(event, newInputValue) => {

              NewAuthor[attr] = newInputValue;
              setValue(NewAuthor);

            }}

          />
        

        
        </Stack>
      );



    
    
    }
    export default AuthorAutoComplete;