import {React, useState, useEffect} from "react";
import Question from './Question';

const Questions = ({api, book_id}) => {

  const [questions, setQuestions] = useState([]);

  useEffect(() => {

      api.getQuestions(book_id)
      .then(result => {
        setQuestions(result);
      })
      .catch(err => {

      });


    }, [api, book_id]);


    return (
        <div className="questions flex" >

          {questions.map(question => (<Question api={api} question={question} key={question.id} book_id={book_id} />))} 

        </div>
      );
    
    
    }
    export default Questions;