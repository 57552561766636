import {React, useState, useEffect} from "react";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const BookAutoComplete = ({api, onSelection}) => {

      const NewBook = {
        id:0,
        Title:""
      };

      const [bookList, setBookList] = useState([]);
      const [value, setValue] = useState(NewBook);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {

        api.getTitles()
        .then(result => {
          setBookList(result);
        })
        .catch(err => {
  
        });
  
  
      }, [api]);


      useEffect(() => {

          onSelection(value);
        
  
      }, [value, onSelection]);

      useEffect(() => {

          //let b = value ? value : NewBook;
          //b.Title = inputValue;
          //setValue(b);
          //onSelection(inputValue);
          //console.log(inputValue);
  
      }, [inputValue]);

      return (
        <Stack spacing={2} sx={{ width: 500 }}>
          <Autocomplete
            id="book-picker"
            freeSolo
            options={bookList}
            getOptionLabel={option => option.Title}
            //renderOption={(option) => <span>{option.Title}</span>}
            
            renderInput={(params) => <TextField {...params} label="Book Name" />}

            value={value || null}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}

            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              NewBook.Title = newInputValue;
              setValue(NewBook);
              setInputValue(newInputValue);
            }}

          />
        
        </Stack>
      );



    
    
    }
    export default BookAutoComplete;