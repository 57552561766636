import {React, useState} from "react";
import Rating from '@mui/material/Rating';




const SubmitReview = ({api, book, onSubmit}) => {

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  function submitReview() {

    api.saveReview(book.id, rating, review)
      .then(r => { 
        onSubmit(r); 
        book.myreview = r;
      })
      .catch(e => alert(e));


  }

    return (
        <div className="review" >
          
          <div><Rating id="avgRating" name="avg-rating" value={review.rating} onChange={(event, newValue) => {setRating(newValue); }} precision={0.5} /></div>
          <div className="reviewBody">

            <textarea  type="text" value={review} onChange={(e) => setReview(e.target.value)}></textarea>
          </div>
          <div>
            <button onClick={submitReview}>Submit</button>
          </div>

        </div>
      );
    
    
    }
    export default SubmitReview;